import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { FormattedMessage } from 'react-intl';

import { ROUTE } from '../../config/routes';
import { useAdmitSelfRegisteredPatientMutation } from '../../services/graphql';

import { PublicContentCard } from '../../components/PublicContentCard/PublicContentCard';
import { OnboardingPasswordForm } from './OnboardingPasswordForm';
import { USER_REGISTRATION_ID } from '../../App.constants';
import { useTracking } from '../../hooks/useTracking';
import { Navbar } from '../../Layouts/GridLayout/PublicLayoutNavbar';
import {
  GridPageCenteredSection,
  GridSection,
} from '../../Layouts/GridLayout/styles';
import { AdmitSelfRegisteredPatientValidationErrors } from './types';
import { Footer } from '../../Layouts/GridLayout/PublicLayoutFooter';

export const OnboardingPassword = () => {
  const { trackPageWithAppName } = useTracking();
  const navigate = useNavigate();
  const location = useLocation();
  const encryptedId = localStorage.getItem(USER_REGISTRATION_ID) as string;

  const { state } = location;

  const [
    createSelfAdmittedPatient,
    { data: selfAdmittedPatient, loading: isSelfAdmittedPatientLoading },
  ] = useAdmitSelfRegisteredPatientMutation();

  const selfAdmittedPatientPayload = {
    ...state,
    encryptedId,
  };

  const selfAdmittedPatientValidationError =
    selfAdmittedPatient?.selfAdmittedPatientAnon as AdmitSelfRegisteredPatientValidationErrors;

  useEffect(() => {
    window.scrollTo(0, 0);
    trackPageWithAppName('Onboarding - password');

    if (selfAdmittedPatient && !selfAdmittedPatientValidationError?.code) {
      localStorage.removeItem(USER_REGISTRATION_ID);
      navigate(ROUTE.ONBOARDING_SUCCESS);
    }
  }, [selfAdmittedPatient]);

  return (
    <>
      <Navbar />
      <GridPageCenteredSection>
        <PublicContentCard
          HeaderText={
            <FormattedMessage
              id="onboarding-password.header"
              defaultMessage="Create your password"
            />
          }
          SubHeaderText={
            <FormattedMessage
              id="onboarding-password.subtitle"
              defaultMessage="Protect your account with a secure password"
            />
          }
        >
          <OnboardingPasswordForm
            createSelfAdmittedPatient={createSelfAdmittedPatient}
            loading={isSelfAdmittedPatientLoading}
            selfAdmittedPatientPayload={selfAdmittedPatientPayload}
            selfAdmittedPatientValidationError={
              selfAdmittedPatientValidationError
            }
          />
        </PublicContentCard>
      </GridPageCenteredSection>
      <GridSection row={2} column={1} columnSpan={14}>
        <Footer />
      </GridSection>
    </>
  );
};
