import {
  Input,
  Notification,
  Stack,
  colors,
  Text,
} from '@bt-healthcare/ui-toolkit';
import { useMemo } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { PasswordValidatorRequirement } from './PasswordValidatorRequirement';

import { PasswordValidatorProps } from './types';

export const PasswordValidator = ({
  form,
  requirements,
  errors: formErrors,
}: PasswordValidatorProps) => {
  const intl = useIntl();

  const {
    register,
    watch,
    formState: { errors },
  } = form;

  const password = watch('password');

  const errorMessage = useMemo(() => {
    if (formErrors?.commonPassword) {
      return 'The password you have entered is not strong enough. Please enter a different password and make sure not to include any personal information or common phrases.';
    }
    if (formErrors?.userExists) {
      return `This account already exists. Please log in or use the 'Forgot my password' function.`;
    }
    if (formErrors?.linkExpired) {
      return 'The user registration link has already expired.';
    }
    if (formErrors?.generic) {
      return 'Something went wrong, please wait a moment and try again.';
    }
    return '';
  }, [formErrors]);

  return (
    <Stack space="s4" id="password-validator">
      <Input
        id="password"
        label={intl.formatMessage({
          id: 'onboarding-password.form.password',
          defaultMessage: 'Password',
        })}
        type="password"
        inputMode="text"
        errorText={errors.password?.message}
        markAsRequired
        {...register('password')}
      />
      <Stack space="s1" id="password-validator.requirements">
        {requirements.map(({ regex, ...requirement }) => (
          <PasswordValidatorRequirement
            key={requirement.id}
            {...requirement}
            isValid={regex.test(password)}
          />
        ))}
      </Stack>
      <Input
        id="confirmPassword"
        label={intl.formatMessage({
          id: 'onboarding-password.form.confirm-password',
          defaultMessage: 'Confirm Password',
        })}
        type="password"
        inputMode="text"
        errorText={errors.confirmPassword?.message}
        markAsRequired
        {...register('confirmPassword')}
      />
      {errorMessage && (
        <Notification type="warning">
          <Text color={colors.grey.grey10}>
            <FormattedMessage
              id="password-validator.error"
              defaultMessage={errorMessage}
            />
          </Text>
        </Notification>
      )}
    </Stack>
  );
};
